<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <img :src="'/images/logo-moldam.png'" alt="MOLDAM" class="mb-3" style="">
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, #FF8E1D, rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="w-full md:w-10 mx-auto">
                        <label for="email1" class="block text-900 text-xl font-medium mb-2">User</label>
                        <InputText id="email1" v-model="email" type="text" class="w-full mb-3" placeholder="Email" style="padding:1rem;" />
                
                        <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
                        <Password id="password1" v-model="password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
                
                        <InlineMessage v-if="wrongLogin" class="w-full">Wrong username or password</InlineMessage>

                        <Button label="Sign In" class="p-button-warning w-full p-3 text-xl mt-5" @click="onLogin"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import store from '@/store';

export default {
    data() {
        return {
            email: '',
            password: '',
            checked: false,
            wrongLogin: false
        }
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    },
    methods: {
        onLogin(){
            const params = new URLSearchParams();
            params.append('username', this.email);
            params.append('password', this.password);
            axios.post('/login', params)
                .then(() => {
                    this.wrongLogin = false;
                    store.commit('setAuthentication', true);
                    store.commit('setUsername', this.email);
                    this.$router.push('/');
                })
                .catch(response => {
                    this.wrongLogin = true;
                    store.commit('setAuthentication', false);
                    store.commit('setUsername', null);
                    console.log("ERROR");
                    console.log(response);
                });
        }
    }
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}
</style>